define("discourse/plugins/discourse-solved-reminders-plugin/discourse/components/solutions-notification-preferences", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group follow-notifications">
    <label class="control-label">{{i18n
        "discourse_solved_reminders.notifications.label"
      }}</label>
  
    <div class="controls">
      <label class="checkbox-label">
        {{input
          type="checkbox"
          checked=this.user.custom_fields.dont_send_accepted_solution_notifications
        }}
        {{i18n
          "discourse_solved_reminders.notifications.dont_send_accepted_solution_notifications"
        }}
      </label>
    </div>
  </div>
  */
  {
    "id": "i7Dp7qRf",
    "block": "[[[10,0],[14,0,\"control-group follow-notifications\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"discourse_solved_reminders.notifications.label\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"user\",\"custom_fields\",\"dont_send_accepted_solution_notifications\"]]]]]],[1,\"\\n      \"],[1,[28,[35,2],[\"discourse_solved_reminders.notifications.dont_send_accepted_solution_notifications\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-solved-reminders-plugin/discourse/components/solutions-notification-preferences.hbs",
    "isStrictMode": false
  });
  class SolutionsNotificationPreferences extends _component.default {}
  _exports.default = SolutionsNotificationPreferences;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SolutionsNotificationPreferences);
});